var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[(!_vm.full_screen)?_c('v-col',{attrs:{"cols":"12","sm":_vm.dash_part,"md":_vm.dash_part}},[_c('v-row',{key:_vm.kchart,ref:"chartRow",staticStyle:{"flex":"1"}},[_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('pchart',{attrs:{"list":_vm.wells_map,"headers":_vm.wells_integrity_status.map(function (x) { return x.integrity_status_name; }),"list_type":"1","data":_vm.wells_integrity_status.map(function (x) { return ({
                value: x.well_nb,
                name: x.integrity_status_name,
                type: x.integrity_status_id,
                itemStyle: {
                  normal: { color: x.color ? x.color : 'gray' },
                },
              }); }),"title":'Wells Integrity'}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('pchart',{attrs:{"list":_vm.wells_map,"headers":_vm.wells_compliance.map(function (x) { return x.compliant_status; }),"list_type":"2","data":_vm.wells_compliance.map(function (x) { return ({
                value: x.well_nb,
                name: x.compliant_status,
                type: x.statut_id,
                itemStyle: { normal: { color: x.color } },
              }); }),"title":'Wells Compliance'}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('bchart',{attrs:{"list_type":"6","type":'bar',"data":_vm.active_failures.map(function (x) { return ({
                value: x.failure_nb,
                name: x.risk,
                type: x.risk,
                itemStyle: { normal: { color: x.color } },
              }); }),"headers":_vm.active_failures.map(function (x) { return x.risk; }),"title":'Active Failures  (' +
              String(
                _vm.active_failures.reduce(
                  function (acc, o) { return acc + parseInt(o.failure_nb); },
                  0
                )
              ) +
              ' failures)'}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('bchart',{attrs:{"list":_vm.wells_map,"list_type":"4","type":'bar',"data":_vm.wells_lifecycle.map(function (x) { return ({
                value: x.well_nb,
                name: x.lifecyclename,
                type: x.statut_id,
                itemStyle: { normal: { color: 'blue' } },
              }); }),"headers":_vm.wells_lifecycle.map(function (x) { return x.lifecyclename; }),"title":'Wells Life Cycle '}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('bchart',{attrs:{"type":'bar',"data":_vm.wells_types.map(function (x) { return ({
                value: x.well_nb,
                name: x.well_type_name,
                type: x.welltype_id,
                itemStyle: { normal: { color: x.color } },
              }); }),"list":_vm.wells_map,"list_type":"3","headers":_vm.wells_types.map(function (x) { return x.well_type_name; }),"title":'Wells Types (' +
              String(
                _vm.wells_types.reduce(function (acc, o) { return acc + parseInt(o.well_nb); }, 0)
              ) +
              ' wells)'}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('bchart',{attrs:{"fluids":_vm.fluids,"fluids_elements":_vm.fluids_elements,"level_list_cor":_vm.level_list_cor,"level_list_cem":_vm.level_list_cem,"tool_list":_vm.tool_list,"user_list":_vm.user_list,"list_type":"5","type":'bar',"data":_vm.wells_tests.map(function (x) { return ({
                value: x.test_nb,
                name: x.testtype,
                type: x.testtype_id,
                itemStyle: { normal: { color: 'blue' } },
              }); }),"headers":_vm.wells_tests.map(function (x) { return x.testtype; }),"title":'Active Tests  (' +
              String(
                _vm.wells_tests.reduce(function (acc, o) { return acc + parseInt(o.test_nb); }, 0)
              ) +
              ' tests)'}})],1)],1)],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","sm":_vm.dash_map,"md":_vm.dash_map,"id":"dMap"}},[_c('GmapMap',{key:_vm.kmap,ref:"mapRef",attrs:{"id":"map","center":_vm.center,"zoom":_vm.zoom,"map-type-id":"hybrid","doubleClickZoom":false,"options":{
          streetViewControl: false,
          mapTypeControl: false,
          fullscreenControl: false,
        }}},[_c('GmapCluster',_vm._l((_vm.wells_map_search),function(pin,index){return _c('GmapMarker',{key:index + 100000000,ref:"markerRefs",refInFor:true,attrs:{"position":pin,"icon":_vm.get_marker(pin)},on:{"mouseover":function($event){_vm.showByIndex = pin},"mouseout":function($event){_vm.showByIndex = null},"click":function($event){return _vm.marker_click(pin)}}},[_c('GmapInfoWindow',{attrs:{"options":{
                pixelOffset: {
                  width: _vm.selectedFlag && _vm.selectedFlag.length > 0 ? -50 : 0,
                  height: _vm.selectedFlag && _vm.selectedFlag.length > 0 ? 78 : 115,
                },
              }}},[_c('div',{style:(_vm.get_label(pin))},[_vm._v(" "+_vm._s(pin.wellname)+" ")])]),_c('GmapInfoWindow',{attrs:{"opened":_vm.showByIndex === pin,"options":{
                zIndex: 9999,
                pixelOffset: {
                  width: _vm.selectedFlag && _vm.selectedFlag.length > 0 ? -40 : 10,
                  height: _vm.selectedFlag && _vm.selectedFlag.length > 0 ? 0 : 20,
                },
              }}},[_c('div',{staticStyle:{"z-index":"99999","font-weight":"bold","color":"#8ebf42","font-family":"arial","background-color":"white","margin":"20px"},domProps:{"innerHTML":_vm._s(_vm.get_info(pin))}})])],1)}),1)],1)],1)],1),_c('div',{attrs:{"id":"dselect"}},[(_vm.full_screen)?_c('v-row',{staticStyle:{"margin":"4px"},attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{staticStyle:{"margin-right":"2px"},attrs:{"dense":"","items":_vm.map_search_items,"item-value":"id","item-text":"value","label":"Choose Integrity","outlined":"","multiple":"","clearable":""},on:{"change":_vm.search_wells},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
              var item = ref.item;
              var index = ref.index;
return [(index < 1)?_c('v-chip',[_c('span',[_vm._v(_vm._s(item.value))])]):_vm._e(),(index === 1)?_c('span',[_vm._v(" (+"+_vm._s(_vm.selectedIntegrity.length - 1)+") ")]):_vm._e()]}}],null,false,4141671025),model:{value:(_vm.selectedIntegrity),callback:function ($$v) {_vm.selectedIntegrity=$$v},expression:"selectedIntegrity"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{staticStyle:{"margin-right":"2px"},attrs:{"dense":"","items":_vm.flags,"item-value":"value","item-text":"label","label":"Choose Flag","outlined":"","clearable":""},on:{"change":_vm.search_wells},model:{value:(_vm.selectedFlag),callback:function ($$v) {_vm.selectedFlag=$$v},expression:"selectedFlag"}})],1)],1):_vm._e()],1),_c('div',{attrs:{"id":"dsearch"}},[(
        _vm.full_screen &&
        ((_vm.selectedIntegrity != null && _vm.selectedIntegrity.length > 0) ||
          _vm.selectedFlag != null)
      )?_c('div',{staticClass:"search"},[_c('v-data-table',{key:_vm.srkey,attrs:{"items":_vm.wells_map_search,"headers":_vm.headers,"hide-default-header":true,"hide-default-footer":_vm.wells_map_search.length < 20,"footer-props":{
          'items-per-page-options': [15],
          'disable-items-per-page': true,
          'page-text': '',
        }},on:{"click:row":_vm.getSelected}})],1):_vm._e()]),_c('div',{attrs:{"id":"rdialog"}},[_c('rapprt',{key:_vm.krapport,attrs:{"well_rapport":_vm.rapport_well,"dialog":_vm.rapport_dialog},on:{"click":function($event){$event.stopPropagation();},"close":_vm.rappor_close}})],1),_c('div',{attrs:{"id":"dsfull_screen"}},[_c('v-btn',{staticStyle:{"margin":"8px"},attrs:{"small":""},on:{"click":_vm.handleFullscreenChange}},[_c('v-icon',[_vm._v("mdi-fullscreen")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }